<template>
    <div
        class="navigation"
        :class="navigationClasses"
    >
        <div class="navigation__bar">
            <div class="navigation__bar__background" />
            <div class="navigation__bar__content">
                <div class="navigation__bar__logo">
                    <NuxtLink
                        :to="localePath({ name: 'index' })"
                        class="link link--image-block"
                        @click="closeMenu"
                    >
                        <TheLogo />
                    </NuxtLink>
                </div>

                <div class="navigation__bar__links">
                    <template v-if="isDoen || isBglf">
                        <template v-if="applyLink.startsWith('https://aanvraag.doen.nl')">
                            <a
                                :href="applyLink"
                                class="navigation__bar__links__button button button--tertiary"
                            >
                                <template v-if="isDoen">{{ t('navigation.links.application_doen') }}</template>
                                <template v-else>{{ t('navigation.links.application') }}</template>
                            </a>
                            <a
                                :href="applyLink"
                                class="navigation__bar__links__apply"
                            >
                                <template v-if="isDoen">{{ t('navigation.links.application_doen') }}</template>
                                <template v-else>{{ t('navigation.links.application') }}</template>
                            </a>
                            <a
                                v-if="isDoen"
                                :href="applyLink"
                                class="navigation__bar__links__account"
                            >
                                <BoomerIcon><icon-user /></BoomerIcon>
                            </a>
                        </template>
                        <template v-else>
                            <NuxtLink
                                :to="localePath(applyLink)"
                                class="navigation__bar__links__button button button--tertiary"
                                @click="closeMenu"
                            >
                                <template v-if="isDoen">{{ t('navigation.links.application_doen') }}</template>
                                <template v-else>{{ t('navigation.links.application') }}</template>
                            </NuxtLink>

                            <nuxt-link
                                :to="localePath(applyLink)"
                                class="navigation__bar__links__apply"
                                @click="closeMenu"
                            >
                                <template v-if="isDoen">{{ t('navigation.links.application_doen') }}</template>
                                <template v-else>{{ t('navigation.links.application') }}</template>
                            </nuxt-link>

                            <a
                                v-if="isDoen"
                                href="https://aanvraag.doen.nl"
                                class="navigation__bar__links__account"
                            >
                                <BoomerIcon><icon-user /></BoomerIcon>
                            </a>
                            <a
                                v-else-if="isBglf"
                                href="https://aanvraag.vriendenloterijfonds.nl"
                                class="navigation__bar__links__account"
                            >
                                <BoomerIcon><icon-user /></BoomerIcon>
                            </a>
                            <NuxtLink
                                v-else
                                :to="localePath({ name: 'mijn' })"
                                class="navigation__bar__links__account"
                                @click="closeMenu"
                            >
                                <BoomerIcon><icon-user /></BoomerIcon>
                            </NuxtLink>
                        </template>
                    </template>

                    <NuxtLink
                        :to="localePath({ name: 'zoek' })"
                        class="navigation__bar__links__search"
                        @click="closeMenu"
                    >
                        <BoomerIcon><icon-search /></BoomerIcon>
                    </NuxtLink>

                    <div
                        class="navigation__bar__hamburger"
                        @click="toggleMenu"
                    >
                        <BaseHamburger />
                    </div>
                </div>
            </div>
        </div>

        <div class="navigation__menu">
            <div class="navigation__menu__content">
                <div class="navigation__menu__main">
                    <NavLink
                        v-for="(link, index) in mainNavigation"
                        :key="index"
                        :type="mapType(link.__typename)"
                        :target="mapTarget(link.targetBlank)"
                        :url="mapURI(link)"
                        :icon="link.icon"
                        class="navigation__menu__mainlink"
                        @click="closeMenu"
                    >
                        {{ link.titel }}
                    </NavLink>
                </div>

                <template v-if="isDoen || isBglf">
                    <NuxtLink
                        :to="localePath(applyLink)"
                        class="navigation__menu__button button button--tertiary"
                        @click="closeMenu"
                    >
                        <template v-if="isDoen">{{ t('navigation.links.application_doen') }}</template>
                        <template v-else>{{ t('navigation.links.application') }}</template>
                    </NuxtLink>
                    <div class="navigation__menu__extralink">
                        <a
                            v-if="isDoen"
                            href="https://aanvraag.doen.nl"
                        >
                            <span>{{ t('navigation.links.account_doen') }}</span>
                            <BoomerIcon><icon-user /></BoomerIcon>
                        </a>
                        <a
                            v-else-if="isBglf"
                            href="https://aanvraag.vriendenloterijfonds.nl"
                        >
                            <span>{{ t('navigation.links.account') }}</span>
                            <BoomerIcon><icon-user /></BoomerIcon>
                        </a>
                        <NuxtLink
                            v-else
                            :to="localePath({ name: 'mijn' })"
                            @click="closeMenu"
                        >
                            <span>{{ t('navigation.links.account') }}</span>
                            <BoomerIcon><icon-user /></BoomerIcon>
                        </NuxtLink>
                    </div>
                </template>

                <template v-if="isParticipaties">
                    <div class="navigation__menu__extralink">
                        <a
                            :href="doenLink"
                            class="navigation__menu__extralink"
                        >
                            <span>{{ t('navigation.links.doen') }}</span>
                            <BoomerIcon><icon-arrow-right-large /></BoomerIcon>
                        </a>
                    </div>
                </template>

                <template v-if="isDoen">
                    <div class="navigation__menu__extralink">
                        <a
                            :href="participatiesLink"
                            class="navigation__menu__extralink"
                        >
                            <span>{{ t('navigation.links.participation') }}</span>
                            <BoomerIcon><icon-arrow-right-large /></BoomerIcon>
                        </a>
                    </div>
                </template>

                <template v-if="isParticipaties || isDoen">
                    <div
                        v-if="(localisations?.length ?? 0) > 0"
                        class="navigation__menu__extralink"
                    >
                        <a
                            v-for="(localisation, key) in localisations"
                            :key="key"
                            :href="localePath(`/${trim(fixHomeUri(localisation.uri), '/')}`, localisation.code)"
                            class="navigation__menu__extralink a"
                        >
                            <span>{{ t('language.switch') }}</span> <BoomerIcon><icon-flag /></BoomerIcon>
                        </a>
                    </div>
                    <div
                        v-else-if="otherLocalePath"
                        class="navigation__menu__extralink"
                    >
                        <a
                            :href="otherLocalePath"
                            class="navigation__menu__extralink"
                        >
                            <span>{{ t('language.switch') }}</span>
                            <BoomerIcon><icon-flag /></BoomerIcon>
                        </a>
                    </div>
                </template>

                <div
                    v-if="isDoen"
                    class="navigation__menu__socials"
                >
                    <a
                        href="https://www.facebook.com/stichtingdoen/?fref=ts"
                        class="navigation__menu__socials__link"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        <BoomerIcon><icon-facebook /></BoomerIcon>
                    </a>
                    <a
                        href="https://twitter.com/StichtingDOEN"
                        class="navigation__menu__socials__link"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        <BoomerIcon><icon-twitter /></BoomerIcon>
                    </a>
                    <a
                        href="https://www.linkedin.com/company/stichting-doen"
                        class="navigation__menu__socials__link navigation__menu__socials__linkedin"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        <BoomerIcon><icon-linkedin /></BoomerIcon>
                    </a>
                    <a
                        href="https://www.youtube.com/user/StichtingDOEN"
                        class="navigation__menu__socials__link"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        <BoomerIcon><icon-youtube /></BoomerIcon>
                    </a>
                    <a
                        href="https://www.instagram.com/stichtingdoen/"
                        class="navigation__menu__socials__link"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        <BoomerIcon><icon-instagram /></BoomerIcon>
                    </a>
                </div>
                <div
                    v-else-if="isBglf"
                    class="navigation__menu__socials"
                >
                    <a
                        href="https://www.facebook.com/bankgiroloterijfonds"
                        class="navigation__menu__socials__link"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        <BoomerIcon><icon-facebook /></BoomerIcon>
                    </a>
                </div>
                <div
                    v-else-if="isParticipaties"
                    class="navigation__menu__socials"
                >
                    <a
                        href="https://www.linkedin.com/showcase/doen-participaties/"
                        class="navigation__menu__socials__link"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        <BoomerIcon><icon-linkedin /></BoomerIcon>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useScroll } from '@vueuse/core';
import { trim } from 'lodash-es';

import IconUser from '~/components/_icons/user.vue';
import IconFlag from '~/components/_icons/flag.vue';
import IconArrowRightLarge from '~/components/_icons/arrow-large-right.vue';
import IconSearch from '~/components/_icons/search.vue';
import IconFacebook from '~/components/_icons/facebook.vue';
import IconTwitter from '~/components/_icons/twitter.vue';
import IconLinkedin from '~/components/_icons/linkedin.vue';
import IconYoutube from '~/components/_icons/youtube.vue';
import IconInstagram from '~/components/_icons/instagram';

import MainNavigation from '~/graphql/queries/MainNavigation.graphql';
import Localizations from '~/graphql/queries/Localizations.graphql';

defineProps({
    applyLink: {
        type: String,
        default: '/aanvraag-doen',
        required: false
    }
});

const { locale, t } = useI18n();
const switchLocalePath = useSwitchLocalePath();
const localePath = useLocalePath();
const { isBglf, isDoen, isParticipaties } = useMultisite();

const { y } = useScroll(window);

const scrolling = computed(() => {
    return y.value > 50;
});

const menuOpen = ref(false);

const navigationClasses = computed(() => {
    let classes = '';

    if (menuOpen.value) {
        classes += 'navigation--open';
    }

    if (scrolling.value) {
        classes += ' navigation--scrolling';
    }

    return classes;
});

const otherLocale = computed(() => locale.value === 'en' ? 'nl' : 'en');

const otherLocalePath = computed(() => switchLocalePath(otherLocale.value));
const doenLink = computed(() => locale.value === 'en' ? 'https://doen.nl/en' : 'https://doen.nl');
const participatiesLink = computed(() => locale.value === 'en' ? 'https://participaties.doen.nl/en' : 'https://participaties.doen.nl');

const { variables: defaultVariables }  = useDefaultVariables();
const variables = computed(() => {
    return {
        ...defaultVariables.value,
        uri: null
    };
});

const { data } = await useAsyncQuery({
    query: MainNavigation,
    variables
});

const mainNavigation = computed(() => data.value?.globalSet?.hoofdnavigatie ?? []);

const { data: localizationsData } = await useAsyncQuery({
    query: Localizations,
    variables: defaultVariables
});

const { localisations } = useLocalisations(localizationsData);

const toggleMenu = () => menuOpen.value = !menuOpen.value;
const closeMenu = () => menuOpen.value = false;
</script>

<script>
import Navigation from '~/mixins/navigation';

export default {
    mixins: [
        Navigation,
    ]
};
</script>

<style lang="less" src="./TheNavigation.less" />
